export enum KakaoShareTemplateType {
  /** 게시글 공유하기 */
  CommunityArticleShare = 'COMMUNITY_ARTICLE_SHARE',
  /** 친구추천 이벤트 링크 공유 */
  ReferralEventCode = 'REFERRAL_EVENT_CODE',
  /** 초대코드 공유하기 */
  FriendInviteShare = 'FRIEND_INVITE_SHARE',
  /** 상품상세 공유하기 */
  ProductDetailShare = 'PRODUCT_DETAIL_SHARE',
  /** 프로필 공유하기 */
  ProfileShare = 'PROFILE_SHARE',
  /** 월부테스트 공유하기 */
  WbTestShare = 'WB_TEST_SHARE',
  /** 공유하기 공용 템플릿 - (thumb, title, description, buttonText) 공유하기 */
  COMMON_TYPE01 = 'COMMON_TYPE01',
}

interface KakaoShareTemplateServerCallbackArgs {
  serverCallbackArgs?: {
    displaySeq: string;
    referralCode: string;
  };
}

interface KakaoShareTemplateForCommunityArticleShare extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.CommunityArticleShare;
  templateArgs: {
    thumb: string;
    favorite: number;
    comment: number;
    contentnumber: string | number;
    comm_title: string;
    inviteCode: string; // 추천코드
    utm_source: string | null;
    utm_medium: string | null;
    utm_campaign: string | null;
  };
}

interface KakaoshareTemplateForReferralEventCode extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.ReferralEventCode;
  templateArgs: {
    inviteCode: string; // 추천코드
  };
}

interface KakaoShareTemplateForFriendInviteShare extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.FriendInviteShare;
  templateArgs: {
    inviteCode: string; // 추천코드
    domain: string;
  };
}

interface KakaoShareTemplateForProductDetailShare extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.ProductDetailShare;
  templateArgs: {
    domain: string;
    thumb: string;
    path: string;
    inviteCode: string; // 추천코드
    utm_source: string | null;
    utm_medium: string | null;
    utm_campaign: string | null;
  };
}

interface KakaoShareTemplateForProfileShare extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.ProfileShare;
  templateArgs: {
    user_nickname: string;
    profile_path: string;
    profile_number: string;
    THU: string; // 이미지 url
  };
}

interface KakaoShareTemplateForWbTest extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.WbTestShare;
  templateArgs: {
    title: string;
    favorite: number;
    pathname: string;
  };
}

interface KakaoShareTemplateCommonType01 extends KakaoShareTemplateServerCallbackArgs {
  type: KakaoShareTemplateType.COMMON_TYPE01;
  templateArgs: {
    thumb: string;
    title: string;
    description: string;
    buttonText: string;
    path: string;
    inviteCode: string; // 추천코드
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
  };
}

export type KakaoShareTemplate =
  | KakaoShareTemplateForCommunityArticleShare
  | KakaoshareTemplateForReferralEventCode
  | KakaoShareTemplateForFriendInviteShare
  | KakaoShareTemplateForProductDetailShare
  | KakaoShareTemplateForProfileShare
  | KakaoShareTemplateForWbTest
  | KakaoShareTemplateCommonType01;
