import { type KakaoShareTemplate, KakaoShareTemplateType } from '../type';

type Returns = (template: KakaoShareTemplate) => void;

export const useShareKakao = (): Returns => {
  const shareKakao = (template: KakaoShareTemplate) => {
    // 자세한 옵션은 https://developers.kakao.com/sdk/reference/js/release/Kakao.Share.html
    window.Kakao.Share.sendCustom({
      templateId: getKakaoTemplateId(template.type),
      // 카카오톡이 설치 되지 않았을때 마켓으로 이동
      installTalk: true,
      templateArgs: template.templateArgs,
      serverCallbackArgs: template.serverCallbackArgs ?? null,
    });
  };

  return shareKakao;
};

const getKakaoTemplateId = (type: KakaoShareTemplateType): number => {
  const {
    public: {
      kakaoCommunityDetailTemplateId,
      kakaoReferralEventTemplateId,
      kakaoFriendInviteTemplateId,
      kakaoProductDetailTemplateId,
      kakaoProfileShareTemplateId,
      kakaoWbTestTemplateId,
      kakaoCommonType01TemplateId,
    },
  } = useRuntimeConfig();

  const KAKAO_TEMPLATE_IDS: Record<KakaoShareTemplateType, number> = {
    [KakaoShareTemplateType.CommunityArticleShare]: Number(kakaoCommunityDetailTemplateId),
    [KakaoShareTemplateType.ReferralEventCode]: Number(kakaoReferralEventTemplateId),
    [KakaoShareTemplateType.FriendInviteShare]: Number(kakaoFriendInviteTemplateId),
    [KakaoShareTemplateType.ProductDetailShare]: Number(kakaoProductDetailTemplateId),
    [KakaoShareTemplateType.ProfileShare]: Number(kakaoProfileShareTemplateId),
    [KakaoShareTemplateType.WbTestShare]: Number(kakaoWbTestTemplateId),
    [KakaoShareTemplateType.COMMON_TYPE01]: Number(kakaoCommonType01TemplateId),
  };

  return KAKAO_TEMPLATE_IDS[type];
};
